<template>
  <div>
    <header id="header">

      <router-link class="logo" to="/">세무관리시스템</router-link>

      <div class="right-menu">
        <button class="user-button" type="button" @click="userMenuOpened=!userMenuOpened">
          <i class="fas fa-user"></i>
          {{loginUser.name}}님 ({{loginUser.companyName}})
          <i class="fas" :class="{'fa-chevron-down':!userMenuOpened, 'fa-chevron-up':userMenuOpened}"></i>
        </button>
        <ul class="user-menu" v-if="userMenuOpened">
          <li @click="OpenForm(loginUser.id)"><router-link tag="button"  to=""  >정보수정</router-link></li>
          <li><button type="button" @click="signOut">로그아웃</button></li>
        </ul>
      </div>
      <users-add-form v-if="ui.viewUsersAddForm"
                      :usr_id="formData.usr_id"
                      @close="ui.viewUsersAddForm=false"
      />
    </header>
    <nav id="nav">
      <ul class="main-navigation">
        <li><router-link class="page-item" to="/" exact>DASHBOARD</router-link></li>
        <li><router-link class="page-item" to="/schedule" exact>일정</router-link></li>
        <li><router-link class="page-item" to="/customers" exact>거래처관리</router-link></li>
        <li>
          <popper
              trigger="clickToOpen"
              :options="{placement: 'top',modifiers: { offset: { offset: '0,10px' } }}">
            <div class="popper favorite-menu">
              <ul class="favorite-menu-list">
                <li v-for="(item,index) in favoriteList" :key="`favorite-${index}`">
                  <a :href="item.fav_url" target="_blank">{{item.fav_title}}</a>
                </li>
                <li class="divider" v-if="favoriteList.length>0"></li>
                <li>
                  <router-link to="/favorite/manager"><i class="fas fa-bookmark"></i> 즐겨찾기 항목 관리</router-link>
                </li>
              </ul>
            </div>

            <a slot="reference" class="page-item">
              <span class="--text">즐겨찾기</span>
            </a>
          </popper>
        </li>
      </ul>

    </nav>
    <div class="user-menu-overlay" @click="userMenuOpened=false" v-if="userMenuOpened"></div>
  </div>

</template>

<style lang="scss" scoped>

.favorite-menu {
  font-size: 13px;
  position: absolute;
  padding: 8px 0;
  background: #ffffff;
  box-shadow: 1px 1px 10px #999;
  border-radius: 3px;
  margin: 0;
  list-style: none;

  .favorite-menu-list {
    list-style:none;
    padding:0;
    margin:0;

    li {
      a {
        display:block;
        padding:5px 8px;
        cursor:default;
        text-decoration: none;
        color:#282828;
        text-align:left;

        &:hover {
          background-color:var(--bs-primary);
          color:#fff;
        }
      }

      &.divider {
        display:block;
        padding:5px;

        &:before {
          content:'';
          display:block;
          width:100%;
          height:1px;
          background:#999;
        }
      }
    }
  }

  .popper__arrow {
    display:none;
  }
}
.user-menu-overlay {
  z-index:100;
  background:transparent;
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
}
#header {
  height:55px;
  background-color:#2d3539;
  display:flex;
  padding:0 16px;
  align-items: center;
  z-index:101;
  flex-shrink: 0;
  position:relative;

  .logo {
    text-decoration: none;
    color:#fff;
  }

  .right-menu {
    margin-left:auto;
    position:relative;
    height:100%;

    .user-button {
      display:block;
      background:transparent;
      border:0 none;
      color:#fff;
      height:100%;
      font-size:12px;

      &:hover {
        background-color:rgba(#2d3539, 0.8);
      }
    }

    .user-menu {
      position:absolute;
      top:100%;
      left:0;
      width:content-width;
      min-width:100%;
      background:#fff;
      list-style:none;
      padding:16px;
      border:1px solid #2d3539;
      margin-top:-1px;
      z-index:103;

      li {
        display:block;
        cursor:pointer;

        button {
          display:block;
          background:transparent;
          border:0 none;
          font-size:12px;
          padding:5px 7px;
          width:100%;

          &:hover {
            background-color:#f0f0f0;
          }
        }
      }
    }
  }
}
#nav {
  height:55px;
  background-color:#2d3539;
  display:flex;
  padding:0 16px;
  align-items: center;
  z-index:100;
  border-top:1px solid hsla(0,0%,100%,.05);
  flex-shrink: 0;
  position:relative;

  .main-navigation {
    list-style:none;
    padding:0;
    margin:0 -16px;
    display:flex;

    li {
      display:block;


      a.page-item {
        display:flex;
        justify-content: center;
        align-items: center;
        height:54px;
        color:#fff;
        text-decoration: none;
        padding:0 16px;
        cursor:pointer;

        &:hover {
          background-color: #2d3133;
        }
        &.router-link-active {
          background-color:#fff;
          color:#2d3539;
        }
      }

    }
  }
}
</style>

<script>
import usersModel from "@/models/users.model";
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';
import favoriteModel from '@/models/favorites.model'
import UsersAddForm from "@/views/System/UsersAddForm";
export default {
  components: {Popper,UsersAddForm},
  data: () => ({
    userMenuOpened: false,
    favoriteList: [],
    ui: {
      viewUsersAddForm: false,
      usr_id : 0
    },
    formData: {
      viewWithdraws: false,
      usr_id: 0
    }
  }),
  mounted() {
    this.getFavoriteList();
  },
  methods: {
    signOut () {
      usersModel.signOutProcess()
    },
    getFavoriteList () {
      favoriteModel.getList().then(res => {
        this.favoriteList = res;
      })
    },
    OpenForm(usr_id) {
      usr_id = typeof usr_id !== 'undefined' && usr_id > 0 ? usr_id : 0
      this.ui.viewUsersAddForm =true
      this.formData.usr_id = usr_id
      console.log(usr_id)
    },
  }
}
</script>