<template>
  <div id="app" :style="{height:`${windowSize.height}px`}">
    <template v-if="useLayout">
      <header-bar v-if="isLogin" />
      <div class="main-layout">
        <left-menu v-if="isLogin&&useSidebar" />
        <main class="contents">
          <router-view/>
        </main>
      </div>
    </template>
    <template v-else>
      <router-view />
    </template>
  </div>
</template>

<style lang="scss" scoped>
#app {
  display:flex;
  flex-direction: column;
  overflow:hidden;

  .main-layout {
    display:flex;
    width:100%;
    flex:1;
    overflow:hidden;

    .contents {
      flex:1;
      overflow-y:auto;
    }
  }
}

</style>
<script>
import HeaderBar from "@/views/Components/Layout/HeaderBar";
import LeftMenu from "@/views/Components/Layout/LeftMenu";
export default {
  components: {
    LeftMenu,
    HeaderBar
  },
  data () {
    return {
      windowSize: {
        width:0,
        height:0
      }
    }
  },
  created() {

  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
  },
  mounted () {
    window.addEventListener('resize', this.onResize)
    this.onResize()
  },
  methods: {

    onResize() {
      this.windowSize.width = window.innerWidth
      this.windowSize.height = window.innerHeight
    }
  }
}
</script>