import {mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters({
            isLogin: 'authorize/isLogin',
            loginUser: 'authorize/userInfo',
            clientList: 'authorize/clientList',
            isLoading : 'layout/isLoading',
            useSidebar : 'layout/useSideBar',
            useLayout : 'layout/useLayout'
        })
    },
    methods: {
        DayFormat( date ) {
            if(date === '' || date === null) return '';

            return date.dateFormat('yyyy-MM-dd (e)')
        },
        onNumberOnlyKeypress (evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault()
            } else {
                return true;
            }
        },
        onPhoneKeyPress (e) {
            e = (e) ? e : window.event;
            var charCode = (e.which) ? e.which : e.keyCode;

            if (charCode !== 8 && charCode !== 0 &&  charCode !== 45 && (charCode < 48 || charCode > 57)) {
                e.preventDefault();
            } else {
                return true;
            }
        },
        openWindow(options) {
            const defaults={
                title : '_blank',
                width : 800,
                height : 600,
                url : ''
            };

            if(typeof options !== 'undefined') {
                for(let key in options) {
                    defaults[key] = options[key];
                }
            }

            const cw = screen.availWidth;
            const ch = screen.availHeight;
            const sw = options.width;
            const sh = options.height;
            const ml = (cw - sw) / 2;
            const mt = (ch - sh) / 2;
            const option = 'width='+sw+',height='+sh+',top='+mt+',left='+ml+',scrollbars=yes,resizable=no';
            const win = window.open(options.url, options.title,  option);

            if (win === null)
            {
                this.$swal("팝업 열기 실패","팝업 차단 기능이 설정되어있습니다<br>차단 기능을 해제(팝업허용) 한 후 다시 이용해 주십시오.","error");
            }

        },
        deepCopy (a, b) {
            if (!this.isEqual(a, b)) {
                return JSON.parse(JSON.stringify(b));
            }
            return a;
        },
        isEqual (a, b) {

            if(a === b) {
                return true;
            }


            if(typeof a !== 'object' || a === null || typeof b !== 'object' || b=== null ) {
                return false;
            }

            if(Array.isArray(a) && Array.isArray(b)) {
                if(a.length !== b.length) {
                    return false;
                }

                for(let i=0;i<a.length; i++) {
                    if(!this.isEqual(a[i], b[i])) {
                        return false;
                    }
                }
                return true;
            }
            else if (!Array.isArray(a) && !Array.isArray(b)) {
                const keysA = Object.keys(a);
                const keysB = Object.keys(b);
                if(keysA.length !== keysB.length) {
                    return false;
                }

                for(let key of keysA) {
                    if(!keysB.includes(key) || !this.isEqual(a[key], b[key])) {
                        return false;
                    }
                }
                return true;
            } else {
                return false;
            }

        }
    }
}