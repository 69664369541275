import UserModel from '@/models/users.model'

export default {
    namespaced: true,
    state: () => ({
        isLogin: false,
        userInfo: {
            id: 0,
            companyId: 0,
            name: '장선근',
            rankName: '과장',
            companyName: '신세계세무회계',
            permission: {},
            isLoaded: false
        },
        clientList: []
    }),
    mutations: {
        setLogin (state) {
            state.isLogin = UserModel.isLogin()
        },
        setUserInfo (state, payload) {
            if(payload === null) {
                state.userInfo.id = 0
                state.userInfo.companyId = 0
                state.userInfo.name = '손님'
                state.userInfo.rankName = ''
                state.userInfo.companyName = ''
                state.userInfo.permission = {}
            }
            else {
                for(let column in payload) {
                    if(typeof state.userInfo[column] !== 'undefined') {
                        state.userInfo[column] = payload[column]
                    }
                }
                state.userInfo.isLoaded = true
            }
        },
        setClientList (state, payload) {
            state.clientList = payload;
        }
    },
    getters: {
        isLogin(state) {
            return state.isLogin
        },
        userInfo (state) {
            return state.userInfo
        },
        clientList (state) {
            return state.clientList
        }
    }
}