/**
 * 사용자 관련 Model
 *
 * @author 장선근
 */
import vue from 'vue'
import store from '@/store'
import jwt from '@/plugins/jwt'

const exportObject = {
    isLogin: () => {
        const accessToken = localStorage.getItem( 'accessToken' )
        return !!(accessToken && accessToken !== 'undefined')
    },
    loginProcess: async (payload) => {
        return await jwt
            .axios
            .post('/v1/users/authorize', {
                loginId: payload.loginId,
                loginPass: payload.loginPass
            })
            .then((res) => {
                if(typeof res.data.accessToken !== 'undefined' && typeof res.data.userInfo !== 'undefined') {
                    localStorage.setItem('userInfo', JSON.stringify(res.data.userInfo))
                    localStorage.setItem('accessToken', res.data.accessToken)
                    localStorage.setItem('refreshToken', res.data.refreshToken)

                    store.commit('authorize/setLogin', true)
                    store.commit('authorize/setUserInfo', res.data.userInfo)

                    vue.prototype.$socket.emit('signIn', {
                        nickname: res.data.userInfo.name,
                        userId: res.data.userInfo.id
                    })
                }
                else {
                    vue.swal('Error', '사용자 로그인에 실패하였습니다','error')
                    store.commit('authorize/setLogin', false)
                    store.commit('authorize/setUserInfo', null)

                    vue.prototype.$socket.emit('signIn', {
                        nickname: res.data.userInfo.name,
                        userId: res.data.userInfo.id
                    })

                    throw new Error('사용자 로그인에 실패')
                }
            })
    },

    async PostUser (params,usr_id) {
        usr_id = typeof usr_id !== 'undefined' && usr_id > 0 ? usr_id : 0
        return await jwt.axios.post(`/v1/users/${usr_id}`, params)
            .then(res => {
                return res.data
            })
    },
    async User_out_in(usr_id,params) {
        usr_id = typeof usr_id !== 'undefined' && usr_id > 0 ? usr_id : 0
        return await  jwt.axios.post(`/v1/users/${usr_id}/status`,params)
            .then(res => {
                return res.data
            })
    },

    async getRankList()  {
        return await jwt.axios.get('/vi/users').then(res => {return res.data});
    },
    /**
     * 사용자 로그아웃 확인 박스
     */
    signOutProcess: () => {
        vue.swal({
            title: '로그아웃',
            text: '로그아웃 하시겠습니까?',
            icon: 'question',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: '로그아웃',
            cancelButtonText: '취소'
        }).then(result => {
            if(result.isConfirmed) {
                vue.prototype.$socket.emit('signIn', {
                    nickname: store.getters["authorize/userInfo"].name,
                    userId: store.getters["authorize/userInfo"].id
                })

                exportObject.signOut()
                location.reload()
            }
        })
    },

    /**
     * 사용자 로그아웃처리
     */
    signOut: () => {
        localStorage.removeItem('userInfo')
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        store.commit('authorize/setLogin', false)
        store.commit('authorize/setUserInfo', null)
        location.reload()
    },

    getUserList: (params) => {
        return jwt.axios
            .get('/v1/users', {
                params: params
            })
            .then(res => {
                return res.data
            })
    },

    getAuth: async (usr_id,formData) => {
        return await jwt.axios.get(`/v1/users/${usr_id}/authorize`,formData);
    },
    setAuth: async (usr_id,formData) =>
    {
        return await jwt.axios.post(`/v1/users/${usr_id}/setauth`,formData)
    },

    getUser: async (usr_id) => {
        return await jwt.axios.get(`/v1/users/${usr_id}`);
    },
    postPassword: async (pwData,usr_id) => {
        return await jwt.axios.post(`/v1/users/${usr_id}/passwordchange`,pwData);
    },

    getMyCommute: async() => {
        return await jwt.axios.get(`/v1/users/commute/today`).then(res => {return res.data});
    },

    checkMyCommute: async(data) => {
        return await jwt.axios.post(`/v1/users/commute/today`, data).then(res => {return res.data});
    },

    getCommuteList : async(type, params) => {
        return await jwt.axios.get(`/v1/users/commute/list/${type}`, {
            params: params
        }).then(res => {return res.data});
    }


}

export default exportObject