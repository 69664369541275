<template>
  <aside class="left-menu">

    <div class="commute-wrap">
      <div class="commute-info">
        <dl>
          <dt>출근시간:</dt>
          <dd>{{myCommute.is_in==='Y'?myCommute.in_time.dateFormat('HH:mm:ss'):''}}</dd>
        </dl>
        <dl>
          <dt>퇴근시간:</dt>
          <dd>{{myCommute.is_out==='Y'?myCommute.out_time.dateFormat('HH:mm:ss'):''}}</dd>
        </dl>
      </div>
      <template v-if="myCommute.is_in === 'N'">
        <button type="button" class="button block mt-2 small" @click="commuteCheck('in')">출근처리</button>
      </template>
      <template v-else-if="myCommute.is_out === 'N'">
        <button type="button" class="button block mt-2 small" @click="commuteCheck('out')">퇴근처리</button>
      </template>
    </div>

    <v-date-picker
        mode="date"
        v-model="selectedDate"
    />

    <div class="mt-2"></div>
    <h4 class="left-title">세무사 일정</h4>
    <ul class="client-list">
      <li class="schedule-item" v-for="(item,index) in schedules.SEMUSA" :key="`s-1-${index}`">
        <div class="color-box" :style="{backgroundColor:item.color}"></div>
        [{{item.semusaUserName}}] {{item.title}}
      </li>
    </ul>

    <div class="mt-2"></div>
    <h4 class="left-title">세무 일정</h4>
    <ul class="client-list">
      <li class="schedule-item" v-for="(item,index) in schedules.SEMU" :key="`s-2-${index}`">
        <div class="color-box" :style="{backgroundColor:item.color}"></div>
        {{item.title}}
      </li>
    </ul>

    <div class="mt-2"></div>
    <h4 class="left-title">사무실 일정</h4>
    <ul class="client-list">
      <li class="schedule-item" v-for="(item,index) in schedules.DEFAULT" :key="`s-3-${index}`">
        <div class="color-box" :style="{backgroundColor:item.color}"></div>
        {{item.title}}
      </li>
    </ul>

    <div class="mt-2"></div>
    <h4 class="left-title">현재접속자</h4>
    <ul class="client-list">
      <li v-for="item in clientList" :key="`client-${item.id}`">{{item.nickname}}</li>
    </ul>

    <commute-form v-if="commuteData.isOpened" :type="commuteData.type" @close="commuteData.isOpened=false" @update="getMyCommute" />
  </aside>
</template>

<style lang="scss" scoped>
.schedule-item {
  display:flex;
  align-items: center;
  font-size:.85rem;
}
.color-box {
  display:block;
  width:12px;
  height:12px;
  border-radius:100%;
  margin-right:3px;
}
.commute-wrap {
  border:1px solid #ccc;
  border-radius:7px;
  padding:8px;
  margin-bottom:.5rem;

  dl {
    display:flex;
    align-items: center;
    margin:0;

    dt {
      font-size:12px;
      font-weight:400;
      margin-bottom:0;
    }
    dd {
      font-size:12px;
      font-weight:400;
      margin-bottom:0;
    }
  }
}
.left-menu {
  border-right:1px solid #c8c8c8;
  align-self: stretch;
  padding:16px;
}

.left-title {
  font-size:1rem;
  padding:.375rem .5rem;
  margin:0;
  background:#907b60;
  color:#fff;
  border:0 none;
}

.client-list {
  list-style:none;
  padding:.5rem 1rem;
  margin:0;
  border:1px solid #c0c0c0;
  border-top:0 none;

  li {
    font-size:.9rem;
  }
}
</style>

<script>
import userModel from '@/models/users.model'
import CommuteForm from "@/views/Users/CommuteForm";
import jwt from "@/plugins/jwt";
import vDatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  components: {CommuteForm, vDatePicker},
  data () {
    return {
      myCommute: {
        in_time: null,
        in_ip: 0,
        in_device: '',
        in_description: '',
        out_time: null,
        out_ip: 0,
        out_device: '',
        out_description: '',
        is_in: 'N',
        is_out: 'N'
      },
      commuteData: {
        isOpened: false,
        type: 'in'
      },
      selectedDate: (new Date()),
      schedules: {
        SEMUSA: [],
        SEMU: [],
        DEFAULT: []
      }
    }
  },
  mounted () {
    this.getMyCommute();
    this.getSchedule();
  },
  watch: {
    selectedDate() {
      this.getSchedule()
    }
  },
  methods: {
    getSchedule () {
      jwt.axios.get('/v1/schedule/summary/' + this.selectedDate.dateFormat('yyyy-MM-dd'))
        .then(res => {
          this.schedules.SEMUSA = res.data.result.SEMUSA
          this.schedules.SEMU = res.data.result.SEMU
          this.schedules.DEFAULT = res.data.result.DEFAULT
        })
    },
    commuteCheck (type) {
      this.commuteData.type = type
      this.commuteData.isOpened = true
    },
    getMyCommute () {
      userModel
        .getMyCommute()
        .then((res) => {
          for(let key in res) {
            if(typeof this.myCommute[key] !== 'undefined') {
              this.myCommute[key] = res[key]
            }
          }
        })
    }
  }
}
</script>