<template>
  <div class="--popup" @click.self="onBackClick">
    <div class="--popup-inner" :style="{width: width + 'px'}">
      <div class="--header">
        <h4 class="--title">{{title}}</h4>
        <button type="button" class="--close" @click="onClose"><i class="fas fa-close"></i></button>
      </div>
      <div class="--body"><slot /></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.--popup {
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:1000;

  &:before {
    content:'';
    display:block;
    position:absolute;
    width:100%;
    height:100%;
    z-index:1001;
    top:0;
    left:0;
    background:rgba(#000, 0.4);
  }

  .--popup-inner {
    position:absolute;
    left:50%;
    top:50%;
    transform:translate(-50%, -50%);
    background:#fff;
    z-index:1002;
    box-shadow: 0 0 2rem rgba(#000, 0.4);
    border:1px solid #797979;

    .--header {
      background:#907b60;
      display:flex;
      align-items:center;
      height:2rem;
      padding:.5rem 1rem;
      position:relative;

      .--title {
        font-family: var(--font-dotum);
        font-size:13px;
        font-weight:700;
        color:#fff;
        margin:0;
      }

      .--close {
        position:absolute;
        top:50%;
        right:.5rem;
        transform: translateY(-50%);
        border:0 none;
        background:transparent;
        color:#fff;
        font-size:1.2rem;

        &:hover {
          color:#d0d0d0;
        }
      }
    }

    .--body {
      padding:1rem;
    }
  }
}
</style>

<script>
export default {
  name: 'PopupLayer',
  props: {
    title: {
      type: String,
      required: false,
      default: '새로운 윈도우'
    },
    width: {
      type: Number,
      required: false,
      default: 400
    },
    backdrop: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    onClose () {
      this.$emit('close')
    },
    onBackClick () {
      if(this.backdrop) {
        this.onClose()
      }
    }
  }
}
</script>