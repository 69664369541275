<template>
  <popup-layer :title="`${type==='in'?'출근':'퇴근'} 처리`" @close="$emit('close')">
    <form @submit.prevent="onSubmit">
      <table class="grid">
        <tr>
          <th>{{type==='in'?'출근':'퇴근'}} 비고사항</th>
          <td>
            <input class="input" v-model.trim="formData.description" />
          </td>
        </tr>
      </table>
      <div class="text-center mt-2">
        <button type="submit" class="button primary">{{type==='in'?'출근':'퇴근'}} 처리</button>
      </div>
    </form>
  </popup-layer>
</template>
<script>
import PopupLayer from "@/views/Components/UI/Popup";
import userModel from '@/models/users.model'

export default {
  props: {
    type: {
      type: String,
      required: false,
      default: 'in'
    }
  },
  data () {
    return {
      formData: {
        description: ''
      }
    }
  },
  components: {PopupLayer},
  methods: {
    onSubmit() {
      let data = {
        description: this.formData.description,
        type: this.type
      }

      userModel.checkMyCommute(data)
          .then(() => {
            this.$emit('update');
            this.$emit('close');
          })
    }
  }
}
</script>