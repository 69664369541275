<template>
  <popup :title="usr_id >0 ? '정보수정' : '신규 사용자 추가'" @close="$emit('close')">
    <form @submit.prevent="onSubmit" autocomplete="off">
      <table class="grid">
        <tr>
          <th>이름 <span class="required">(필수입력)</span></th>
          <td>
            <input class="input" v-model.trim="formData.usr_name" required>
          </td>
          <td>

            <label class="checkbox">
              <input type="checkbox" v-model="formData.usr_is_semusa" true-value="Y" false-value="N">
              <span class="checkbox-label">세무사</span>
            </label>
          </td>
        </tr>
        <tr>
          <th>직책</th>
          <td colspan="2">
            <select class="input" v-model.number="formData.usr_rank_id">
              <option :value="0">없음</option>
              <option v-for="(item,index) in database.CstatusList" :key="`com_id-${index}`" :value="item.com_id">{{item.com_name}}</option>
            </select>
          </td>
        </tr>
        <tr>
          <th>아이디 <span class="required">(필수입력)</span></th>
          <td colspan="2">
            <input class="input" v-model.trim="formData.usr_login_id" :readonly="readonly()" required >
          </td>
        </tr>
        <tr v-if="usr_id ===0">
          <th>비밀번호 <span class="required">(필수입력)</span></th>
          <td colspan="2">
            <div class="input-wrap">
              <input :type="ui.visiblePassword?'text':'password'" class="input" v-model.trim="formData.usr_login_pass" required>
              <button class="button input-addon-button" type="button" @click="ui.visiblePassword=!ui.visiblePassword"><i class="fa" :class="ui.visiblePassword?'fa-eye-slash':'fa-eye'"></i></button>
            </div>
          </td>
        </tr>
        <tr v-if="usr_id ===0">
          <th>비밀번호확인 <span class="required">(필수입력)</span></th>
          <td colspan="2">
            <div class="input-wrap">
              <input :type="ui.visiblePasswordConfirm?'text':'password'" class="input" v-model.trim="formData.loginPassConfirm" required>
              <button class="button input-addon-button" type="button" @click="ui.visiblePasswordConfirm=!ui.visiblePasswordConfirm"><i class="fa" :class="ui.visiblePasswordConfirm?'fa-eye-slash':'fa-eye'"></i></button>
            </div>
          </td>
        </tr>

        <tr>
          <th>휴대폰</th>
          <td colspan="2">
            <input class="input" v-model.trim="formData.usr_phone" @keypress="onPhoneKeyPress" @keyup="onPhoneKeyUp">
          </td>
        </tr>
        <tr>
          <th>E-mail</th>
          <td colspan="2">
            <input class="input" v-model.trim="formData.usr_email">
          </td>
        </tr>
        <tr>
          <th>우편번호</th>
          <td>
            <input class="input" v-model.trim="formData.usr_zonecode" readonly>
          </td>
          <td class="W130">
            <button type="button" class="button input-addon-button">우편번호 찾기</button>
          </td>
        </tr>
        <tr>
          <th>주소</th>
          <td colspan="2">
            <input class="input" v-model.trim="formData.usr_address1" readonly>
          </td>
        </tr>
        <tr>
          <th>상세 주소</th>
          <td colspan="2">
            <input class="input" v-model.trim="formData.usr_address2">
          </td>
        </tr>
        <tr>
          <th>입사일</th>
          <td colspan="2">
            <input type="date" class="input" v-model.trim="formData.usr_in_dt">
          </td>
        </tr>
      </table>
      <div class="button-flex">
        <button type="button" class="button warning mt-2" @click="openPassword(usr_id)" v-if="usr_id !== 0 "  >비밀번호 변경</button>
        <button type="submit" class="button primary mt-2">사용자 등록</button>

      </div>
    </form>
    <PasswordChange
        v-if="ui.visible"
        :usr_id="pwData.usr_id"
        @close="ui.visible=false"
    >
    </PasswordChange>
  </popup>

</template>
<script>

import Popup from "@/views/Components/UI/Popup";
import UserModel from "@/models/users.model";
import systemModel from '@/models/system.model';
import PasswordChange from '@/views/System/UserPasswordChange'
export default {
  components: {Popup,PasswordChange},
  props: {
    usr_id: {
      type: [Number, String],
      required: false,
      default: 0
    }

  },
  data() {
    return {
      ui: {
        visible : false,
        visiblePassword: false,
        visiblePasswordConfirm: false
      },
      rankList: {
        com_id: '',
        com_type: '',
        com_status: '',
        com_name: ''
      },
      database: {
        CstatusList: []
      },
      formData: {
        usr_id: 0,
        usr_login_id: '',
        usr_login_pass: '',
        loginPassConfirm: '',
        usr_name: '',
        usr_rank_id: 0,
        usr_phone: '',
        usr_zonecode: '',
        usr_address1: '',
        usr_address2: '',
        usr_email: '',
        usr_is_semusa: '',
        usr_in_dt: '',
        usr_memo: ''
      },
      pwData: {
        usr_id : 0
      }
    }
  },
  mounted() {
    this.formData.usr_id = this.usr_id
    if (this.formData.usr_id) {
      this.getUser()
    }
    this.getBaseCodes();
  },
  computed: {
    rankListData() {
      return this.rankList.filter(item => item.status === 'Y')
    }
  },
  methods: {
    getUser() {
      UserModel
          .getUser(this.usr_id)
          .then(res => {
            this.formData = res.data;
          });
    },
    getRankList() {
      UserModel.getRankList().then(res => this.getRankList = res);
    },
    onClose() {
      this.$emit('close')
    },
    onSubmit() {
      const usr_id = this.usr_id > 0 ? this.usr_id : 0
      let formData = {
        usr_login_id: this.formData.usr_login_id,
        usr_login_pass: this.formData.usr_login_pass,
        //loginPassConfirm: this.formData.,
        usr_name: this.formData.usr_name,
        usr_rank_id: this.formData.usr_rank_id,
        usr_phone: this.formData.usr_phone,
        usr_zonecode: this.formData.usr_zonecode,
        usr_address1: this.formData.usr_address1,
        usr_address2: this.formData.usr_address2,
        usr_email: this.formData.usr_email,
        usr_is_semusa: this.formData.usr_is_semusa,
        usr_in_dt: this.formData.usr_in_dt,
        usr_memo: this.formData.usr_memo,
        status:'Y'
      }

      UserModel
          .PostUser(formData, usr_id)
          .then(() => {
            this.$emit('update')
            this.$emit('close')
            if (usr_id > 0) {
              this.$swal('수정완료', '정보수정이 완료되었습니다.', "success")
            } else {
              this.$swal('등록완료', '신규 사용자등록이 완료되었습니다.', "success")
            }
          })
    },
    getBaseCodes() {
      systemModel
          .GetBaseCodeList('직책')
          .then(res => {
            this.database.CstatusList = res.result
          })
    },
    readonly() {
      const chk = this.usr_id > 0
      return chk
    },
    onPhoneKeyUp () {
      this.formData.phone = this.formData.phone.replace(/[^0-9]/g, "").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,"$1-$2-$3").replace("--", "-")
    },

    openPassword(usr_id) {
      usr_id = typeof usr_id !== 'undefined' && usr_id > 0 ? usr_id : 0
      this.ui.visible = true
      this.pwData.usr_id = usr_id
    },
  }
}
</script>

<style lang="scss" scoped>
.button-flex {
  display: flex;
  justify-content: space-between;
}
</style>