import jwt from '@/plugins/jwt'

const exportObject = {
    async getList () {
        return await jwt.axios.get('/v1/favorites').then(res=> res.data);
    },
    async getOne (id) {
        return await jwt.axios.get('/v1/favorites/' + id).then(res=> res.data);
    },
    async postData (params, id) {
        id = typeof id !== 'undefined' && id > 0 ? id : ''
        return await jwt.axios.post('/v1/favorites/' + id, params);
    },
    async deleteData (id) {
        return await jwt.axios.delete('/v1/favorites/' + id);
    },
    async sortChange (formData) {
        return await jwt.axios.post('/v1/favorites/sort-change', {
            sortData: formData
        });
    }
}

export default exportObject