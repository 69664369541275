<template>
  <popup-layer title="비밀번호 수정" @close="$emit('close')" >
    <div class="grid-wrapper">
      <form @submit.prevent="onSubmit" autocomplete="off">
        <table class="grid">

          <tr >
            <th>비밀번호 <span class="required">(필수입력)</span></th>
            <td colspan="2">
              <div class="input-wrap">
                <input :type="ui.visiblePassword?'text':'password'" class="input" v-model.trim="pwData.password" required>
                <button class="button input-addon-button" type="button" @click="ui.visiblePassword=!ui.visiblePassword"><i class="fa" :class="ui.visiblePassword?'fa-eye-slash':'fa-eye'"></i></button>
              </div>
            </td>
          </tr>
          <tr >
            <th>비밀번호확인 <span class="required">(필수입력)</span></th>
            <td colspan="2">
              <div class="input-wrap">
                <input :type="ui.visiblePasswordConfirm?'text':'password'" class="input" v-model.trim="pwData.passwordconfirm" required>
                <button class="button input-addon-button" type="button" @click="ui.visiblePasswordConfirm=!ui.visiblePasswordConfirm"><i class="fa" :class="ui.visiblePasswordConfirm?'fa-eye-slash':'fa-eye'"></i></button>
              </div>
            </td>
          </tr>
        </table>
        <div class="button-flex">
          <button type="submit" class="button block warning mt-2" >비밀번호 변경</button>


        </div>
      </form>
    </div>
  </popup-layer>
</template>
<script>
import PopupLayer from "@/views/Components/UI/Popup";
import UserModel from '@/models/users.model'
export default {
  components: {PopupLayer},
  props: {
    usr_id: {
      type: [Number, String],
      require: false,
      default: 0
    }
  },
  data() {
    return {
      ui: {
        visible : false,
        visiblePassword: false,
        visiblePasswordConfirm: false
      },
      pwData : {
        usr_id : 0,
        password: '',
        passwordconfirm: ''
      }
    }
  },
  methods: {
    onSubmit() {
     const usr_id = this.usr_id > 0 ? this.usr_id : 0
      let pwData = {
        usr_id : this.usr_id,
        password: this.pwData.password,
        passwordconfirm: this.pwData.passwordconfirm
      }
      UserModel
      .postPassword(pwData,usr_id)
      .then(() => {
        this.$emit('update')
        this.$emit('close')
        this.$swal('변경완료', '비밀번호 변경이 완료되었습니다.', "success")
      })
    }
  }
}
</script>