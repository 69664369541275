import jwt from '@/plugins/jwt'

export default {
    async GetBaseCodeList ( type ) {
        type = encodeURIComponent(type);
        return await jwt
            .axios
            .get(`/v1/system/basecode/${type}`)
            .then(res => {
                return res.data
            })
    },

    async PostBaseCode (type, formData, id) {
        id = typeof id !== 'undefined' && id >0 ? ('/' + id) : ''
        type = encodeURIComponent(type);

        return await jwt.axios.post('/v1/system/basecode/' + type + id, formData )
    },

    async DeleteBaseCode (type, id) {
        type = encodeURIComponent(type);

        return await jwt.axios.delete(`/v1/system/basecode/${type}/${id}`)
    }
}