import Vue from 'vue'
import VueRouter from 'vue-router'
import usersModel from "@/models/users.model";
import store from '@/store'
Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'home', component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue') },
  { path: '/sign-in', name: 'signIn', component: () => import(/* webpackChunkName: "users.sign-in" */ '../views/Users/SignIn.vue'), meta:{requiredLogin:false} },
  { path: '/schedule', name: 'Schedule', component: () => import(/* webpackChunkName: "Schedule" */ '../views/Schedule/ScheduleView') },
  { path: '/system/users', name: 'UsersManagement', component: () => import(/* webpackChunkName: "system.users" */ '../views/System/UsersList') },
  { path: '/system/basecode', name: 'BaseCodeManagement', component: () => import(/* webpackChunkName: "system.basecode" */ '../views/System/BasecodeList') },
  { path: '/board/:brdKey', name:'BoardPostList', component: () => import(/* webpackChunkName: "board.postList" */ '../views/Board/PostList')  },
  { path: '/board/:brdKey/write', name:'BoardPostWrite', component: () => import(/* webpackChunkName: "board.postWrite" */ '../views/Board/PostWrite')  },
  { path: '/board/:brdKey/:id', name:'BoardPostView', component: () => import(/* webpackChunkName: "board.postView" */ '../views/Board/PostView')  },
  { path: '/board/:brdKey/:id/edit', name:'BoardPostEdit', component: () => import(/* webpackChunkName: "board.postEdit" */ '../views/Board/PostWrite')  },
  {
    path: '/customers',
    name:'CustomersList',
    component: () => import(/* webpackChunkName: "customers" */ '../views/Customers/CustomerLayout'),
    meta:{sidebar:false },
    children: [
      {path: ':id', name:'CustomerInfo', component: () => import(/* webpackChunkName: "customers.info" */ '../views/Customers/CustomerInfo'), meta:{sidebar:false, selectedTab: 0 },},
      {path: ':id/accounts', name:'CustomerAccountList', component: () => import(/* webpackChunkName: "customers.accountList" */ '../views/Customers/AccountList.vue'),meta:{sidebar:false, selectedTab: 1 },},
      {path: ':id/documents', name:'CustomerDocumentList', component: () => import(/* webpackChunkName: "customers.documentList" */ '../views/Customers/DocumentsList.vue'),meta:{sidebar:false, selectedTab: 2 },},
      {path: ':id/consulting', name:'CustomerConsultingList', component: () => import(/* webpackChunkName: "customers.documentList" */ '../views/Customers/ConsultingList.vue'),meta:{sidebar:false, selectedTab: 3 },},
      {path: ':id/payroll', name:'CustomerPayrollList', component: () => import(/* webpackChunkName: "customers.documentList" */ '../views/Customers/PayrollList.vue'),meta:{sidebar:false, selectedTab: 4 },},
      {path: ':id/vat-summary', name:'CustomerVatSummary', component: () => import(/* webpackChunkName: "customers.documentList" */ '../views/Customers/VatSummary.vue'),meta:{sidebar:false, selectedTab: 5 },},
      {path: ':id/interim-closing-summary', name:'CustomerInterimClosingSummary', component: () => import(/* webpackChunkName: "customers.documentList" */ '../views/Customers/InterimClosingSummary.vue'),meta:{sidebar:false, selectedTab: 6 },},
      {path: ':id/corporate-tax-summary', name:'CustomerCorpTaxSummary', component: () => import(/* webpackChunkName: "customers.documentList" */ '../views/Customers/CorporateTaxSummary.vue'),meta:{sidebar:false, selectedTab: 7 },},
      {path: ':id/closing-report', name:'CustomerClosingReport', component: () => import(/* webpackChunkName: "customers.documentList" */ '../views/Customers/ClosingReport.vue'),meta:{sidebar:false, selectedTab: 8 },},
    ]
  },
  { path: '/customers/:id/print', name:'CustomersPrint', component: () => import(/* webpackChunkName: "customers.print" */ '../views/Customers/CustomerPrint'), meta:{sidebar:false, layout: false }  },
  { path: '/favorite/manager', name:'favoriteManager', component: () => import(/* webpackChunkName: "favoriteManager" */ '../views/Favorites/FavoriteList') },
  { path: '/send/alimtalk', name:'SendAlimtalk', component: () => import(/* webpackChunkName: "send.alimtalk" */ '../views/Sends/SendAlimtalk') },
  { path: '/tax/withholding', name:'WithholdingTax', component: () => import(/* webpackChunkName: "tax.widthholding" */ '../views/Tax/WithholdingTax'), meta:{sidebar:false } },
  { path: '/tax/vat', name:'VatTax', component: () => import(/* webpackChunkName: "tax.vat" */ '../views/Tax/VatTax'), meta:{sidebar:false } },
  { path: '/users/commute', name:'VatTax', component: () => import(/* webpackChunkName: "users.commute" */ '../views/Users/CommuteList'), meta:{sidebar:true } },
  { path: '/users/commute/:type', name:'VatTax', component: () => import(/* webpackChunkName: "users.commute" */ '../views/Users/CommuteList'), meta:{sidebar:true } },
  { path: '/setting', name:'Setting', component: () => import(/* webpackChunkName: "setting" */ '../views/System/Setting')},
  { path: '*', name: 'Error404NotFound', component: () => import(/* webpackChunkName: "error.404" */ '../views/Extra/Error404') }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from, next) => {
  const isRequiredLogin = !(typeof to.meta !== 'undefined' && typeof to.meta.requiredLogin !== 'undefined' && to.meta.requiredLogin === false);
  const useSidebar = !(typeof to.meta !== 'undefined' && typeof to.meta.sidebar !== 'undefined' && to.meta.sidebar === false);
  const useLayout =  !(typeof to.meta !== 'undefined' && typeof to.meta.layout !== 'undefined' && to.meta.layout === false);

  store.commit('layout/setSidebar', useSidebar);
  store.commit('layout/setLayout', useLayout);

  if(isRequiredLogin && ! usersModel.isLogin()) {
    next('/sign-in')
  }
  else {
    next();
  }
})

export default router
