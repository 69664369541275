export default {
    namespaced: true,
    state: () => ({
        isLoading: false,
        useSidebar: true,
        useLayout: true
    }),
    mutations: {
        setLoading (state, payload) {
            state.isLoading = payload
        },
        setSidebar (state, payload) {
            state.useSidebar = payload
        },
        setLayout (state, payload) {
            state.useLayout = payload
        }
    },
    actions: {

    },
    getters: {
        isLoading (state) {
            return state.isLoading
        },
        useSideBar (state) {
            return state.useSidebar
        },
        useLayout (state) {
            return state.useLayout
        }
    }
}