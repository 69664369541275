import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// Bootstrap
import 'bootstrap/dist/css/bootstrap.css'

// Custom Css
import '@/scss/style.scss'

// SweetAlert
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css';
const options = {
  confirmButtonColor: '#907b60',
  cancelButtonColor: '#282828',
};
Vue.use(VueSweetalert2, options)

import mixins from './mixins'
Vue.mixin(mixins);

// VueMeta
import Meta from 'vue-meta'
Vue.use(Meta, {
  attribute: 'data-vue-meta'
});

// Date 형식을 Formatting 하는 스크립트
import '@/plugins/dateFormat'

// 새로고침등을 했을때 로그인이 되어있는지 여부를 다시 체크
import userModel from '@/models/users.model'
if(userModel.isLogin())
{
  store.commit('authorize/setLogin', true)
  store.commit('authorize/setUserInfo', JSON.parse( window.localStorage.getItem('userInfo')))
}
import Calendar from 'v-calendar/lib/components/calendar.umd'
import datePicker from 'v-calendar/lib/components/date-picker.umd'

Vue.component('vCalendar', Calendar, {
  popoverVisibility:'focus'
})

Vue.use(datePicker, {
  isRequired: true,
  masks: {
    input: 'YYYY-MM-DD',
    data: 'YYYY-MM-DD'
  },
  popover: {
    visibility: 'click'
  }
})

// Vue Notification
import VueNativeNotification from 'vue-native-notification'
Vue.use(VueNativeNotification, {
  requestOnNotify: true
})

// Vue Tooltip
import VTooltip from 'v-tooltip' //추가
Vue.use(VTooltip); //추가

import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client';

// Vue Toastr
import VueToastr from 'vue-toastr'
Vue.use(VueToastr, {
  defaultTimeout: 1500
});



Vue.use(new VueSocketIO({
  debug: true,
  connection: SocketIO(  process.env.NODE_ENV === 'production' ? 'https://app-api.shin3tax.net': 'http://localhost:4000', {
    cors: { origin: '*',  credentials: true },
    query: {
      nickname: userModel.isLogin() ? store.getters["authorize/userInfo"].name : "손님",
      userId: userModel.isLogin() ? store.getters["authorize/userInfo"].id * 1 : 0
    }
  }),
  vuex: {
    store,
    actionPrefix: 'SOCKET_',
    mutationPrefix: 'SOCKET_'
  }
}))
/*
import io from 'socket.io-client';
const socket = io('http://localhost:4001', {
  cors: { origin: '*',  credentials: true },
  query: {
    nickname: userModel.isLogin() ? store.getters["authorize/userInfo"].name : "손님",
    userId: userModel.isLogin() ? store.getters["authorize/userInfo"].id * 1 : 0
  }
});

socket.on('getClientList', (data)=> {
  console.log(data)

  store.commit('authorize/setClientList', data);
})

Vue.prototype.$socket = socket;
*/
new Vue({
  sockets: {
    getClientList (data) {
      store.commit('authorize/setClientList', data);
    }
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app')
